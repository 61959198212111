var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
//Generated by: TsAppClientApiPrinter
//Contact #dev-ui-platform-support in case of issues
import { GeneratedApiClient } from "../ApiClient";
import { HttpMethod } from "../HttpClient";
/* tslint:disable:no-namespace */
export var DriverAuthNoAuthService;
(function (DriverAuthNoAuthService) {
    var ApiClientClass = /** @class */ (function (_super) {
        __extends(ApiClientClass, _super);
        function ApiClientClass() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            /** Generated from: {@link [SmsWebAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/controller/SmsWebAuthenticationController.ts)} */
            _this.authenticateWithMagicLink = function (query, body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/authenticateWithMagicLink",
                            service_id: "driver-auth",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SmsWebAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/controller/SmsWebAuthenticationController.ts)} */
            _this.startAuthentication = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/startAuthentication",
                            service_id: "driver-auth",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SmsWebAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/controller/SmsWebAuthenticationController.ts)} */
            _this.resendVerificationCode = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/resendVerificationCode",
                            service_id: "driver-auth",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SmsWebAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/controller/SmsWebAuthenticationController.ts)} */
            _this.v2ConfirmAuthentication = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/v2/confirmAuthentication",
                            service_id: "driver-auth",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SmsWebAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/controller/SmsWebAuthenticationController.ts)} */
            _this.getAccessToken = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/getAccessToken",
                            service_id: "driver-auth",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: false },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            return _this;
        }
        return ApiClientClass;
    }(GeneratedApiClient));
    // Split class actual prototype and type
    DriverAuthNoAuthService.ApiClient = ApiClientClass;
    /** Generated from: {@link [VerificationAuthenticationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/common/authentication/async/controller/VerificationAuthenticationController.ts)} */
    var AppStore;
    (function (AppStore) {
        AppStore["GOOGLE"] = "google";
        AppStore["APPLE"] = "apple";
        AppStore["HUAWEI"] = "huawei";
    })(AppStore = DriverAuthNoAuthService.AppStore || (DriverAuthNoAuthService.AppStore = {}));
    /** Generated from: {@link [BrandHelper.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/common/util/brand/BrandHelper.ts)} */
    var BrandEnum;
    (function (BrandEnum) {
        BrandEnum["BOLT"] = "bolt";
        BrandEnum["HOPP"] = "hopp";
    })(BrandEnum = DriverAuthNoAuthService.BrandEnum || (DriverAuthNoAuthService.BrandEnum = {}));
    /** Generated from: {@link [PartnerDriverAuthService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/service/PartnerDriverAuthService.ts)} */
    var ConfirmAuthenticationResponseType;
    (function (ConfirmAuthenticationResponseType) {
        ConfirmAuthenticationResponseType["TOKEN"] = "token";
        ConfirmAuthenticationResponseType["EMAIL_VERIFICATION"] = "email_verification";
    })(ConfirmAuthenticationResponseType = DriverAuthNoAuthService.ConfirmAuthenticationResponseType || (DriverAuthNoAuthService.ConfirmAuthenticationResponseType = {}));
    /** Generated from: {@link [PartnerDriverAuthService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/service/PartnerDriverAuthService.ts)} */
    var RefreshTokenType;
    (function (RefreshTokenType) {
        RefreshTokenType["DRIVER"] = "driver";
        RefreshTokenType["PARTNER"] = "partner";
    })(RefreshTokenType = DriverAuthNoAuthService.RefreshTokenType || (DriverAuthNoAuthService.RefreshTokenType = {}));
    /** Generated from: {@link [PartnerDriverAuthService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/fleet/main/driver_auth/service/PartnerDriverAuthService.ts)} */
    var VerificationCodeChannelClientOption;
    (function (VerificationCodeChannelClientOption) {
        VerificationCodeChannelClientOption["SMS"] = "sms";
        VerificationCodeChannelClientOption["CALL"] = "call";
    })(VerificationCodeChannelClientOption = DriverAuthNoAuthService.VerificationCodeChannelClientOption || (DriverAuthNoAuthService.VerificationCodeChannelClientOption = {}));
})(DriverAuthNoAuthService || (DriverAuthNoAuthService = {}));
