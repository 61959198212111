import * as React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import ErrorHint from '../common/ErrorHint';
import { BaseInputProps } from './BaseInput';
import FieldDescription from '../common/FieldDescription';

interface Choise {
  value: string | number | null;
  title: string;
}

interface SelectProps extends BaseInputProps<string | number> {
  choices: Choise[];
  hideLabel?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
}

const isChoise = (v: unknown): v is Choise => {
  return !!v && (v as Choise).value !== undefined;
};

const customStyles: StylesConfig<Choise> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#FFFFFF' : '#F4F4F6',
    borderColor: state.isFocused ? '#5B68F6' : '#F4F4F6',
    borderWidth: '2px',
    boxShadow: 'none',
    padding: '8px 12px',
    ':hover': {
      ...provided[':hover'],
      borderColor: state.isFocused ? '#5B68F6' : '#F4F4F6',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#2F313F',
    padding: 0,
    transform: state.isFocused ? 'rotate(180deg)' : 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#999999',
    padding: '4px 8px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    color: '#999999',
    marginLeft: '2px',
    marginRight: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    background: '#FFFFFF',
    position: 'absolute',
    left: 0,
    right: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '8px 24px',
    minHeight: '40px',
    lineHeight: '24px',
    backgroundColor: state.isSelected ? '#EBECFF' : '#FFFFFF',
    color: '#2F313F',
    ':hover': {
      ...provided[':hover'],
      backgroundColor: state.isSelected ? '#EBECFF' : '#F6F6F6',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    paddingTop: '3px',
    paddingBottom: '3px',
  }),
  input: (provided) => ({
    ...provided,
    height: '18px',
  }),
};

const errorStyles: StylesConfig<Choise> = {
  control: (provided, state) => ({
    ...provided,
    ...(customStyles.control && customStyles.control(provided, state)),
    borderColor: 'rgba(235, 71, 85, 1)',
    ':hover': {
      ...provided[':hover'],
      borderColor: 'rgba(235, 71, 85, 1)',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    ...(customStyles.dropdownIndicator &&
      customStyles.dropdownIndicator(provided, state)),
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    ...(customStyles.clearIndicator &&
      customStyles.clearIndicator(provided, state)),
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    ...(customStyles.indicatorSeparator &&
      customStyles.indicatorSeparator(provided, state)),
  }),
  menu: (provided, state) => ({
    ...provided,
    ...(customStyles.menu && customStyles.menu(provided, state)),
  }),
  option: (provided, state) => ({
    ...provided,
    ...(customStyles.option && customStyles.option(provided, state)),
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    ...(customStyles.valueContainer &&
      customStyles.valueContainer(provided, state)),
  }),
  input: (provided, state) => ({
    ...provided,
    ...(customStyles.input && customStyles.input(provided, state)),
  }),
};

export const Select: React.FC<SelectProps> = ({
  choices,
  title,
  description,
  value,
  updateHandler,
  blurHandler,
  error,
  hideLabel,
  placeholder,
  isSearchable,
}: SelectProps) => {
  const selectedValue =
    choices.find((choise) => choise.value === value) || undefined;

  return (
    <div className="w-full">
      {!hideLabel && (
        <label
          htmlFor={title}
          className="text-black font-bold text-sm text-left rtl:text-right"
        >
          {title}
        </label>
      )}
      <div className="w-full my-2">
        <ReactSelect
          id={title}
          name={title}
          placeholder={placeholder || ''}
          defaultValue={selectedValue}
          options={choices}
          getOptionLabel={(item: Choise) => item.title?.toString() ?? ''}
          getOptionValue={(item: Choise) => item.value?.toString() ?? ''}
          styles={error ? errorStyles : customStyles}
          isSearchable={isSearchable}
          isClearable
          // blurInputOnSelect (triggers autosave prematurely)
          closeMenuOnSelect
          onChange={(option) => {
            const newVal = isChoise(option) ? option.value : null;
            updateHandler(newVal);
          }}
          onBlur={(e) => {
            blurHandler(e);
          }}
        />
      </div>
      <ErrorHint error={error} />
      <FieldDescription description={description} />
    </div>
  );
};

Select.defaultProps = {
  hideLabel: false,
  placeholder: '',
  isSearchable: false,
};
