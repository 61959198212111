import React, { useState } from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import MarkdownWrapper from '../common/MarkdownWrapper';
import { LoadingIcon } from '../common/LoadingIcon';

interface ColoredIconBannerProps {
  data: DriverRegistrationPortalNoAuthService.ColoredIconBanner;
}

export const ColoredIconBanner: React.FC<ColoredIconBannerProps> = ({
  data,
}: ColoredIconBannerProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (!data.title_html) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: data.background_color }}
      className="rounded-2xl border p-3 ml-2 mr-2"
    >
      <div className="flex flex-row">
        <div className="pr-3 my-auto">
          <img
            src={data.icon_url}
            alt="banner_icon"
            width={48}
            height={48}
            style={{ display: isImageLoaded ? 'block' : 'none' }}
            onLoad={() => setIsImageLoaded(true)}
          />
          {!isImageLoaded && (
            <LoadingIcon className="text-purple-500 animate-spin mx-auto" />
          )}
        </div>
        <div className="justify-center flex-col">
          <div className="pb-1 pt-1">
            <MarkdownWrapper>{data.title_html}</MarkdownWrapper>
          </div>
          {data.description_html ? (
            <div className="pb-1">
              <MarkdownWrapper>{data.description_html}</MarkdownWrapper>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
