import React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { useSelector } from 'react-redux';
import { DocumentUpload } from './input/DocumentUpload';
import { BaseField } from './BaseField';
import {
  enhancedCaptureDocumentKeys,
  isDocumentResubmission,
  useEnhancedDocumentCapture,
} from '../redux/form/formSelectors';
import { EnhancedDocumentUpload } from './input/EnhancedDocumentUpload';

interface FieldProps {
  commonTranslations: DriverRegistrationPortalNoAuthService.CommonTranslations;
  fieldDefinition: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (data: {
    changed: DriverRegistrationPortalNoAuthService.Field['current_value'];
    autosaveEnabled: boolean;
  }) => void;
  blurHandler: (
    event: React.FocusEvent<HTMLInputElement, Element>,
    autosave: { enabled: boolean; always?: boolean }
  ) => void;
  hash: string;
  step: DriverRegistrationPortalNoAuthService.Step;
}

export const Field: React.FC<FieldProps> = ({
  commonTranslations,
  fieldDefinition,
  updateHandler,
  blurHandler,
  hash,
  step,
}: FieldProps): React.ReactElement | null => {
  const enrolledToEnhancedCapture = useSelector(useEnhancedDocumentCapture);
  const isResubmittingDocuments = useSelector(isDocumentResubmission);
  const useEnhancedCapture =
    !isResubmittingDocuments && enrolledToEnhancedCapture;
  const supportedDocuments = useSelector(enhancedCaptureDocumentKeys);
  const useDeeplink = !!(
    useEnhancedCapture &&
    fieldDefinition.document_type_key &&
    supportedDocuments.includes(fieldDefinition.document_type_key)
  );

  switch (fieldDefinition.type) {
    case DriverRegistrationPortalNoAuthService.FrontendFieldDefinitionType
      .DOCUMENT_UPLOAD:
      return useEnhancedCapture ? (
        <EnhancedDocumentUpload
          commonTranslations={commonTranslations}
          field={fieldDefinition}
          step={step}
          updateHandler={(v) =>
            updateHandler({ changed: v, autosaveEnabled: false })
          }
          hash={hash}
          useDeeplink={useDeeplink}
        />
      ) : (
        <DocumentUpload
          commonTranslations={commonTranslations}
          field={fieldDefinition}
          step={step}
          updateHandler={(v) =>
            updateHandler({ changed: v, autosaveEnabled: false })
          }
          hash={hash}
        />
      );
    default:
      return (
        <BaseField
          fieldDefinition={fieldDefinition}
          updateHandler={updateHandler}
          blurHandler={blurHandler}
        />
      );
  }
};
