import { AuthType, SupportWidget } from '@bolteu/support-widget';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DriverRegistrationPortalService } from '@bolteu/bolt-server-api-partner-driver-web';
import jwtDecode from 'jwt-decode';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import partnerRegistrationApi from '../api/PartnerRegistrationApi';
import { Logger } from '../common/logger';
import { PartnerDriverAuthStrategy } from '../api/auth_strategies/PartnerDriverAuthStrategy';
import { useSelector } from '../redux/store';
import SupportType = DriverRegistrationPortalService.SupportType;
import DriverRegistrationLogStep = DriverRegistrationPortalService.DriverRegistrationLogStep;
import SupportCondition = DriverRegistrationPortalService.SupportCondition;
import { getLanguage } from '../redux/localization/localizationSelectors';

const supportWidget = new SupportWidget();

type ParsedJWTToken = { exp: number };

interface SupportWidgetLoaderProps {
  step: DriverRegistrationPortalNoAuthService.DriverRegistrationLogLastStep;
  hasValidationErrors: boolean;
}

const SupportWidgetLoader = ({
  step,
  hasValidationErrors,
}: SupportWidgetLoaderProps) => {
  const [isSupportWidgetFeatureEnabled, setIsSupportWidgetFeatureEnabled] =
    useState(false);
  const [isSupportWidgetInitialised, setIsSupportWidgetInitialised] =
    useState(false);
  const [offeredSupportCondition, setOfferedSupportCondition] = useState<
    SupportCondition | undefined
  >();
  const [supportWidgetTimeout, setSupportWidgetTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const locale = useSelector(getLanguage);

  const getAuthToken = useCallback(async () => {
    const willAccessTokenExpireSoon = (
      decodedJwtToken: ParsedJWTToken,
      validForSeconds: number
    ): boolean => {
      return (
        !decodedJwtToken.exp ||
        decodedJwtToken.exp * 1000 - Date.now() < validForSeconds * 1000
      );
    };

    let token = PartnerDriverAuthStrategy.getAuthToken();

    const parsedAccessToken: ParsedJWTToken | undefined = jwtDecode(
      token || ''
    );
    const validForSeconds =
      PartnerDriverAuthStrategy.getExtraConfig()
        .accessTokenRefreshTimeoutBeforeExpiryInSeconds || 0;

    if (!parsedAccessToken) {
      throw new Error('Could not parse access token');
    }

    if (willAccessTokenExpireSoon(parsedAccessToken, validForSeconds)) {
      token =
        await PartnerDriverAuthStrategy.getExtraConfig().updateAuthToken();
    }

    if (!token) {
      throw new Error('Missing authentication token');
    }

    return token;
  }, []);

  const initWidget = useCallback(async () => {
    if (!isSupportWidgetInitialised) {
      try {
        const widgetResponse =
          await partnerRegistrationApi.driverRegistrationSupportGetSupportWidgetUrl();
        const widgetUrl = widgetResponse.url;
        if (widgetUrl && widgetUrl.length) {
          supportWidget.init({
            url: widgetUrl,
            auth: {
              type: AuthType.JWT,
              requestToken: getAuthToken,
            },
            locale,
          });
          setIsSupportWidgetInitialised(true);
        } else {
          Logger.log('No widget URL found, unable to initialise widget');
        }
      } catch (e) {
        // Ignoring
      }
    }
  }, [getAuthToken, isSupportWidgetInitialised, locale]);

  useEffect(() => {
    return () => {
      if (supportWidgetTimeout) {
        clearTimeout(supportWidgetTimeout);
      }
    };
  }, [supportWidgetTimeout]);

  const showSupportWidgetInstantly = useMemo(
    () => async () => {
      setOfferedSupportCondition(SupportCondition.IMMEDIATE);
      await initWidget();
    },
    [initWidget]
  );

  const showSupportWidgetWithDelay = useMemo(
    () => async (delayInSeconds: number) => {
      setOfferedSupportCondition(SupportCondition.WITH_DELAY);
      setSupportWidgetTimeout(
        setTimeout(async () => {
          await initWidget();
        }, delayInSeconds * 1000)
      );
    },
    [initWidget]
  );

  const showSupportWidgetOnValidationFailed = useMemo(
    () => () => {
      setOfferedSupportCondition(SupportCondition.VALIDATION_FAILED);
    },
    [setOfferedSupportCondition]
  );

  useEffect(() => {
    if (offeredSupportCondition === SupportCondition.VALIDATION_FAILED) {
      if (
        hasValidationErrors &&
        isSupportWidgetFeatureEnabled &&
        !isSupportWidgetInitialised
      ) {
        initWidget();
      }
    }
  }, [
    hasValidationErrors,
    isSupportWidgetFeatureEnabled,
    isSupportWidgetInitialised,
    offeredSupportCondition,
    initWidget,
  ]);

  const determineSupportType = useCallback(
    (
      result: DriverRegistrationPortalService.GetAvailableSupportOptionsResponse
    ) => {
      setIsSupportWidgetFeatureEnabled(
        result.support_type.type !== SupportType.DEFAULT
      );
      switch (result.support_type.type) {
        case SupportType.CHAT: {
          switch (result.support_type.conditions[0].type) {
            case SupportCondition.IMMEDIATE:
              showSupportWidgetInstantly();
              break;
            case DriverRegistrationPortalService.SupportCondition.WITH_DELAY:
              showSupportWidgetWithDelay(
                result.support_type.conditions[0].delay_in_seconds
              );
              break;
            case SupportCondition.VALIDATION_FAILED:
              showSupportWidgetOnValidationFailed();
              break;
            default:
              Logger.log('Invalid condition type for showing support widget');
          }
          break;
        }
        default:
        // No-op
      }
    },
    [
      showSupportWidgetInstantly,
      showSupportWidgetOnValidationFailed,
      showSupportWidgetWithDelay,
    ]
  );

  useEffect(() => {
    let active = true;
    if (!isSupportWidgetInitialised) {
      const load = async () => {
        try {
          const result =
            await partnerRegistrationApi.driverRegistrationSupportGetAvailableSupportOptions(
              { step: step as unknown as DriverRegistrationLogStep }
            );
          if (active) {
            determineSupportType(result);
          }
        } catch (e) {
          // Ignoring
        }
      };

      load();
    }
    return () => {
      active = false;
    };
  }, [step, determineSupportType, isSupportWidgetInitialised]);

  return <></>;
};

export { SupportWidgetLoader };
