import * as React from 'react';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import { Select } from './Select';
import { useSelector } from '../../redux/store';
import { getFieldError } from '../../redux/notification/notificationsSelectors';

interface CascadeSelectProps {
  field: DriverRegistrationPortalNoAuthService.Field;
  updateHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
  blurHandler: (
    changed: DriverRegistrationPortalNoAuthService.Field['current_value']
  ) => void;
}

export function CascadeSelect({
  field,
  updateHandler,
  blurHandler,
}: CascadeSelectProps): React.ReactElement {
  const error = useSelector(getFieldError(field.name));
  const choices = field.select_values || [];
  const initial = choices.find((option) =>
    option.subitems?.some((sub) => sub.value === field.current_value)
  );
  const [selectedFirstValue, setSelectedFirstValue] = React.useState<
    string | number | null
  >(initial?.value || null);

  const isCascade = choices.some((v) => !!v.subitems);
  if (!isCascade) {
    return (
      <Select
        title={field.translations.label}
        description={field.translations.description}
        placeholder={field.translations.placeholder}
        choices={choices}
        value={field.current_value}
        error={error}
        updateHandler={updateHandler}
        blurHandler={blurHandler}
        isSearchable={field.is_searchable}
      />
    );
  }

  const subItems = choices.find(
    (o) => o.value === selectedFirstValue
  )?.subitems;

  let mainDescription: string | undefined = field.translations.description;
  let mainError = error;
  let subDescription;
  let subError;

  if (selectedFirstValue != null) {
    mainDescription = undefined;
    mainError = undefined;
    subDescription = field.translations.description;
    subError = error;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col w-full items-center">
        <div className="flex w-full flex-row">
          <Select
            title={field.translations.label}
            description={mainDescription}
            placeholder={field.translations.placeholder}
            choices={choices}
            value={selectedFirstValue}
            error={mainError}
            updateHandler={setSelectedFirstValue}
            blurHandler={() => {
              // Ignore this blur and do nothing
            }}
            isSearchable={field.is_searchable}
          />
        </div>
        {selectedFirstValue && subItems && (
          <div className="flex w-full mt-2 flex-row">
            <Select
              title={`sub-${field.translations.label}`}
              description={subDescription}
              choices={subItems}
              value={field.current_value}
              error={subError}
              updateHandler={updateHandler}
              blurHandler={blurHandler}
              isSearchable={field.is_searchable}
              hideLabel
            />
          </div>
        )}
      </div>
    </div>
  );
}
