var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//!!!! WARNING !!!!!!!!
//This is an auto generated file.
//Changes to this code will be lost if the code is regenerated.
//Generated by: TsAppClientApiPrinter
//Contact #dev-ui-platform-support in case of issues
import { GeneratedApiClient } from "../ApiClient";
import { HttpMethod, AuthStrategy } from "../HttpClient";
/* tslint:disable:no-namespace */
export var DriverRegistrationPortalService;
(function (DriverRegistrationPortalService) {
    var ApiClientClass = /** @class */ (function (_super) {
        __extends(ApiClientClass, _super);
        function ApiClientClass() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            /** Generated from: {@link [RegistrationController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/RegistrationController.ts)} */
            _this.getActualRegistration = function (opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/getActualRegistration",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            }
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
            _this.triggerDocumentUpload = function (body, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/triggerDocumentUpload",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            body: body
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
            _this.getCaptureMode = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/getCaptureMode",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [DocumentCaptureController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/DocumentCaptureController.ts)} */
            _this.uploadDriverRegistrationDocument = function (query, files, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/partnerDriverWeb/uploadDriverRegistrationDocument",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.POST,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query,
                            files: files
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SupportOptionsController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/SupportOptionsController.ts)} */
            _this.driverRegistrationSupportGetAvailableSupportOptions = function (query, opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/support/getAvailableSupportOptions",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            },
                            query: query
                        }, opts)];
                });
            }); };
            /** Generated from: {@link [SupportOptionsController.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/controller/SupportOptionsController.ts)} */
            _this.driverRegistrationSupportGetSupportWidgetUrl = function (opts) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.httpClient.sendRequest({
                            path: "/driverRegistration/support/getSupportWidgetUrl",
                            service_id: "driver-registration-portal",
                            subdomain: "partnerdriverweb",
                            http_method: HttpMethod.GET,
                            meta: {
                                auth: { enabled: true, strategy: AuthStrategy.JWT },
                                sharding: { enabled: false },
                                virtualPaths: { enabled: (_b = (_a = this.apiClientOptions) === null || _a === void 0 ? void 0 : _a.virtualPathsEnabled) !== null && _b !== void 0 ? _b : true }
                            }
                        }, opts)];
                });
            }); };
            return _this;
        }
        return ApiClientClass;
    }(GeneratedApiClient));
    // Split class actual prototype and type
    DriverRegistrationPortalService.ApiClient = ApiClientClass;
    /** Generated from: {@link [BrandHelper.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/common/util/brand/BrandHelper.ts)} */
    var BrandEnum;
    (function (BrandEnum) {
        BrandEnum["BOLT"] = "bolt";
        BrandEnum["HOPP"] = "hopp";
    })(BrandEnum = DriverRegistrationPortalService.BrandEnum || (DriverRegistrationPortalService.BrandEnum = {}));
    /** Generated from: {@link [DriverRegistrationService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/admin/service/DriverRegistrationService.ts)} */
    var DriverRegistrationLogSource;
    (function (DriverRegistrationLogSource) {
        DriverRegistrationLogSource["DRIVER_PORTAL"] = "driver_portal";
        DriverRegistrationLogSource["FLEET_PORTAL"] = "fleet_owner_portal";
    })(DriverRegistrationLogSource = DriverRegistrationPortalService.DriverRegistrationLogSource || (DriverRegistrationPortalService.DriverRegistrationLogSource = {}));
    /** Generated from: {@link [Types.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/common/driver/constants/Types.ts)} */
    var DriverRegistrationLogStatus;
    (function (DriverRegistrationLogStatus) {
        DriverRegistrationLogStatus["IN_PROGRESS"] = "in_progress";
        DriverRegistrationLogStatus["SUBMITTED"] = "submitted";
        DriverRegistrationLogStatus["ONBOARDING"] = "onboarding";
        DriverRegistrationLogStatus["DECLINED"] = "declined";
        DriverRegistrationLogStatus["DECLINED_SILENTLY"] = "declined_silently";
        DriverRegistrationLogStatus["ACCEPTED"] = "accepted";
        DriverRegistrationLogStatus["DOCUMENTS_NEED_RESUBMISSION"] = "documents_need_resubmission";
        DriverRegistrationLogStatus["DOCUMENTS_RESUBMITTED"] = "documents_resubmitted";
        DriverRegistrationLogStatus["DELETED"] = "deleted";
        DriverRegistrationLogStatus["PENDING_COMPANY_APPROVAL"] = "pending_company_approval";
    })(DriverRegistrationLogStatus = DriverRegistrationPortalService.DriverRegistrationLogStatus || (DriverRegistrationPortalService.DriverRegistrationLogStatus = {}));
    /** Generated from: {@link [Steps.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/common/constants/Steps.ts)} */
    var DriverRegistrationLogStep;
    (function (DriverRegistrationLogStep) {
        DriverRegistrationLogStep["STEP1"] = "step_1";
        DriverRegistrationLogStep["STEP2"] = "step_2";
        DriverRegistrationLogStep["STEP3"] = "step_3";
        DriverRegistrationLogStep["STEP4"] = "step_4";
        DriverRegistrationLogStep["STEP5"] = "step_5";
        DriverRegistrationLogStep["STEP6"] = "step_6";
    })(DriverRegistrationLogStep = DriverRegistrationPortalService.DriverRegistrationLogStep || (DriverRegistrationPortalService.DriverRegistrationLogStep = {}));
    /** Generated from: {@link [SupportOptionsService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/service/SupportOptionsService.ts)} */
    var SupportCondition;
    (function (SupportCondition) {
        SupportCondition["IMMEDIATE"] = "immediate";
        SupportCondition["WITH_DELAY"] = "with_delay";
        SupportCondition["VALIDATION_FAILED"] = "validation_failed";
    })(SupportCondition = DriverRegistrationPortalService.SupportCondition || (DriverRegistrationPortalService.SupportCondition = {}));
    /** Generated from: {@link [SupportOptionsService.ts](https://github.com/bolteu/bolt-server/blob/e5c4b383e0b250fc1a0924badc2a31e9c1173038/src/rides/driver/driver_registration/main/portal/service/SupportOptionsService.ts)} */
    var SupportType;
    (function (SupportType) {
        SupportType["DEFAULT"] = "default";
        SupportType["ARTICLE"] = "article";
        SupportType["CHAT"] = "chat";
    })(SupportType = DriverRegistrationPortalService.SupportType || (DriverRegistrationPortalService.SupportType = {}));
})(DriverRegistrationPortalService || (DriverRegistrationPortalService = {}));
