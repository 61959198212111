import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Logo } from './components/common/Logo';
import { SignupFormLayout } from './components/SignupFormLayout';
import { NotFound } from './components/NotFound';
import LanguageSelector from './components/common/LanguageSelector';
import { VerificationWrapper } from './components/verification/VerificationWrapper';
import { saveAppVersionToCookies } from './redux/common/commonHelpers';
import { PickOtpChannel } from './components/verification/PickOtpChannel';
import { AppPromotion } from './components/app_promotion/AppPromotion';

export const App: React.FC = () => {
  React.useEffect(() => {
    saveAppVersionToCookies();
  }, []);

  return (
    <>
      <Logo />
      <Switch>
        <Route path="/driver">
          <Switch>
            <Route path="/driver/verification/:token">
              <VerificationWrapper />
            </Route>
            <Route path="/driver/pickOtpChannel/:token">
              <PickOtpChannel />
            </Route>
            <Route path="/driver/appPromotion/">
              <AppPromotion />
            </Route>
            <Route path="/driver/404">
              <NotFound />
            </Route>
            <Route exact>
              <SignupFormLayout />
            </Route>
          </Switch>
        </Route>
        <Redirect path="*" to="/driver/404" />
      </Switch>
      <LanguageSelector />
    </>
  );
};
