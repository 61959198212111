import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DriverRegistrationPortalNoAuthService } from '@bolteu/bolt-server-api-driver-registration';
import {
  getCurrentStep,
  getCurrentStepFieldValue,
  getCurrentStepName,
  getHash,
} from '../form/formSelectors';
import { IAppState, ThunkMiddlware } from '../types';
import {
  autosaveFailed,
  autosavePending,
  autosaveSaving,
  autosaveSuccessful,
} from './autosaveActions';

import {
  getAutosaveFieldName,
  getAutosaveFieldValue,
  getAutosaveTimeout,
  isAutosavePending,
} from './autosaveSelectors';
import { RegistrationApiClient } from '../../api/types/apiTypes';
import { getLanguage } from '../localization/localizationSelectors';
import { buildPostFormPayload } from '../form/formHelpers';
import { handleAutosaveFieldValidationErrors } from '../../api/ApiErrorsHandler';
import { clearFieldError } from '../notification/notificationActions';
import { Logger } from '../../common/logger';

const AUTOSAVE_IDLE_TIMEOUT = 2 * 1000; // 2000 ms = 2 s

const clearAutosavePendingTimeout = (getState: () => IAppState) => {
  const state = getState();

  if (!isAutosavePending(state)) {
    return;
  }

  const timeout = getAutosaveTimeout(state);
  clearTimeout(timeout!);
};

const fieldHasDifferentValue = (
  fieldName: string,
  fieldValue: any,
  getState: () => IAppState
): boolean => {
  const state = getState();
  const currentFieldValue = getCurrentStepFieldValue(state, fieldName);

  return JSON.stringify(currentFieldValue) !== JSON.stringify(fieldValue);
};

const flushAutosaveField = async (
  dispatch: Dispatch<any>,
  getState: () => IAppState,
  registrationApi: RegistrationApiClient
) => {
  const state = getState();

  if (!isAutosavePending(state)) {
    return;
  }

  const fieldName = getAutosaveFieldName(state);
  const fieldValue = getAutosaveFieldValue(state);

  if (!fieldName) {
    return;
  }

  const hash = getHash(state);
  const language = getLanguage(state);
  const lastStep = getCurrentStepName(state);
  const currentStep = getCurrentStep(state);

  if (!currentStep) {
    return;
  }

  dispatch(autosaveSaving());

  const isGenericField = !!currentStep.fields.find((f) => f.name === fieldName)
    ?.generic_field_key;
  const { signup_data: signupData } = buildPostFormPayload(
    currentStep,
    lastStep
  );
  const body = {
    field_key: isGenericField ? 'generic_fields' : fieldName,
    generic_field_key: isGenericField ? fieldName : undefined,
    signup_data: {
      ...signupData,
      [fieldName]: fieldValue,
      last_step: lastStep,
    },
  };

  try {
    await registrationApi.autosave(
      {
        hash,
        language,
      },
      body
    );
    // if there is different value for the same field, we ignore the previous result
    if (!fieldHasDifferentValue(fieldName, fieldValue, getState)) {
      dispatch(clearFieldError(fieldName));
      dispatch(autosaveSuccessful());
    }
  } catch (e) {
    if (!fieldHasDifferentValue(fieldName, fieldValue, getState)) {
      dispatch(autosaveFailed());
      handleAutosaveFieldValidationErrors(e, fieldName, dispatch);
    }
  }
};

const scheduleAutosaveField = async (
  fieldName: string,
  fieldValue: any,
  dispatch: Dispatch<any>,
  getState: () => IAppState,
  registrationApi: RegistrationApiClient,
  waitingTime = AUTOSAVE_IDLE_TIMEOUT
) => {
  const state = getState();

  const doSchedule = () => {
    const timeout = setTimeout(() => {
      flushAutosaveField(dispatch, getState, registrationApi);
    }, waitingTime);
    dispatch(autosavePending(fieldName, fieldValue, timeout));
  };

  if (!isAutosavePending(state)) {
    // If there's no pending timeout we schedule it.
    doSchedule();
  } else {
    // Otherwise there are two possibilities:
    //
    // 1. It's a timeout for the same field. In this case we silently cancel it
    //    and then schedule a new one.
    //
    // 2. It's a timeout for another field. In this case we should simply flush
    //    the auto-save first and schedule a new one.

    const pendingFieldName = getAutosaveFieldName(state);
    const oldTimeout = getAutosaveTimeout(state);

    if (oldTimeout === null) {
      Logger.log(`autosave is pending, yet no timeout found`);
    } else {
      clearTimeout(oldTimeout);
    }

    if (fieldName !== pendingFieldName) {
      await flushAutosaveField(dispatch, getState, registrationApi);
    }

    doSchedule();
  }
};

export const autosaveHandleUpdate: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> =
  (field: DriverRegistrationPortalNoAuthService.Field, value: any) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    scheduleAutosaveField(
      field.name,
      value,
      dispatch,
      getState,
      registrationApi
    );
  };

export const autosaveHandleBlur: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> =
  (field: DriverRegistrationPortalNoAuthService.Field) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    const value = getCurrentStepFieldValue(getState(), field.name);
    scheduleAutosaveField(
      field.name,
      value,
      dispatch,
      getState,
      registrationApi,
      0
    );
  };

export const autosaveHandleBlurOnChangedValue: ActionCreator<
  ThunkAction<void, IAppState, ThunkMiddlware, Action<void>>
> =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => IAppState,
    { registrationApi }
  ) => {
    clearAutosavePendingTimeout(getState);
    flushAutosaveField(dispatch, getState, registrationApi);
  };
